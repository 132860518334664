import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { indicatorStoreSetStateDebounced, useIndicatorStore } from '~/store/useIndicatorStore';
/** 雙均交叉 */
export const goldbrain_bandtrade = createIndicator({
    id: 'goldbrainbandtrade',
    displayName: '長線釣大魚',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const state = useIndicatorStore.getState();
            const positionType = useChartPositionStore.positionType;
            const isCheckDay = this.isSettlementDay();
            const symbol = context.symbol.info?.ticker;
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            //商品數值 開高低收 時間
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close = this.PineJS.Std.close(this._context);
            const itime = this.PineJS.Std.time(this._context);
            //商品數值 開高低收Array
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const close_array = this._context.new_var(close);
            //部位相關 狀態
            const bState = this._context.new_var();
            const bDt = this._context.new_var();
            const pvflag = this._context.new_var();
            const entryPrice = this._context.new_var();
            const entryTime = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            const exitPrice = this._context.new_var();
            //部位相關 買賣、停利訊號
            let pv0 = NaN;
            let pv1 = NaN;
            let stoploss0 = NaN;
            let target0 = NaN;
            let percent0 = NaN;
            let stoploss1 = NaN;
            let target1 = NaN;
            let percent1 = NaN;
            bState.get(1);
            low_array.get(1);
            high_array.get(1);
            entryPrice.get(1);
            /** 短均線 */
            const input0 = 33; //this._input(0)
            /** 長均線 */
            const input1 = 89; //this._input(1)
            /** 停損 */
            const input2_stopLoss = 200; //this._input(2)
            /** 停利 */
            const input3_target = 100; //this._input(3)
            /** 百分停利門檻 */
            const input4_datum = 80; //this._input(4)
            /** 百分停利 */
            const input5_percentage = 30; //this._input(5)
            /** 多空參數控制 */
            const setPosition = 0; //this._input(6)
            const targetValue = (l1, l2, l3, l4, l5) => {
                if (close < 10)
                    return l1;
                if (close < 50)
                    return l2;
                if (close < 100)
                    return l3;
                if (close < 500)
                    return l4;
                if (close < 5000)
                    return l5;
            };
            // const targetp1 = 0.05
            // const targetp2 = 0.1
            // const targetp3 = 0.2
            // const targetp4 = 0.4
            const p1 = targetValue(0.05, 0.05, 0.06, 0.06, 0.06) ?? 0.005;
            const p2 = targetValue(0.12, 0.12, 0.13, 0.13, 0.13) ?? 0.01;
            const p3 = targetValue(0.24, 0.24, 0.25, 0.25, 0.25) ?? 0.02;
            const p4 = targetValue(0.48, 0.48, 0.49, 0.49, 0.49) ?? 0.04;
            const sma1 = this.PineJS.Std.sma(close_array, input0, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, input1, this._context);
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            const buy_condition = this.PineJS.Std.cross(sma1, sma2, this._context) && sma1 > sma2;
            const short_condition = this.PineJS.Std.cross(sma1, sma2, this._context) && sma1 < sma2;
            //部位進場--------------------------------------------
            if (positionType !== 'short' && buy_condition) {
                bState.set(1);
                entryTime.set(itime);
            }
            if (positionType !== 'long' && short_condition) {
                bState.set(-1);
                entryTime.set(itime);
            }
            //部位進場--------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv1 = NaN;
                pvflag.set(0);
                entryPrice.set(close);
                entryHigh.set(high);
                state.entryPrice = Number(close);
                state.entryTime = Number(itime);
                state.marketPosition = Number(1);
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv1 = 1;
                pvflag.set(0);
                entryPrice.set(close);
                entryLow.set(low);
                state.entryPrice = Number(-close);
                state.entryTime = Number(itime);
                state.marketPosition = Number(-1);
            }
            //停損設置
            if (bState.get(0) === 1 && bState.get(1) === 1 && low < entryPrice.get(0) * 0.9) {
                stoploss0 = 1;
                bState.set(0);
                state.entryPrice = Number(0);
                state.entryTime = Number(itime);
                state.marketPosition = Number(0);
            }
            if (bState.get(0) === -1 && bState.get(1) === -1 && high > entryPrice.get(0) * 1.1) {
                stoploss1 = 1;
                bState.set(0);
                state.entryPrice = Number(0);
                state.entryTime = Number(itime);
                state.marketPosition = Number(0);
            }
            //出場設置
            if (bState.get(0) === 1 && bState.get(1) === 1) {
                //月結算出場
                if (isCheckDay === true) {
                    bState.set(0);
                    target0 = 1;
                    state.entryPrice = Number(0);
                    state.entryTime = Number(itime);
                    state.marketPosition = Number(0);
                }
                if (entryHigh > entryPrice * (1 + p1) && pvflag.get(0) === 0) {
                    percent0 = 1;
                    pvflag.set(1);
                }
                if (entryHigh > entryPrice * (1 + p2) && pvflag.get(0) === 1) {
                    percent0 = 1;
                    pvflag.set(2);
                }
                if (entryHigh > entryPrice * (1 + p3) && pvflag.get(0) === 2) {
                    percent0 = 1;
                    pvflag.set(3);
                }
                if (entryHigh > entryPrice * (1 + p4) && pvflag.get(0) === 3) {
                    percent0 = 1;
                    pvflag.set(4);
                }
                if (high > entryPrice * (1 + p4) && pvflag.get(0) === 4) {
                    percent0 = 1;
                    //bState.set(0)
                    pvflag.set(5);
                    state.entryPrice = Number(0);
                    state.entryTime = Number(itime);
                    state.marketPosition = Number(0);
                }
                if (entryHigh > entryPrice * (1 + p3) &&
                    pvflag.get(0) === 3 &&
                    close < entryHigh - (input5_percentage / 100) * (entryHigh - entryPrice)) {
                    target0 = 1;
                    bState.set(0);
                    pvflag.set(4);
                }
            }
            if (bState.get(0) === -1 && bState.get(1) === -1) {
                //月結算出場
                if (isCheckDay === true) {
                    bState.set(0);
                    target1 = 1;
                    state.entryPrice = Number(0);
                    state.entryTime = Number(itime);
                    state.marketPosition = Number(0);
                }
                if (entryLow < entryPrice * (1 - p1) && pvflag.get(0) === 0) {
                    percent1 = 1;
                    pvflag.set(1);
                }
                if (entryLow < entryPrice * (1 - p2) && pvflag.get(0) === 1) {
                    percent1 = 1;
                    pvflag.set(2);
                }
                if (entryLow < entryPrice * (1 - p3) && pvflag.get(0) === 2) {
                    percent1 = 1;
                    pvflag.set(3);
                }
                if (entryLow < entryPrice * (1 - p4) && pvflag.get(0) === 3) {
                    percent1 = 1;
                    pvflag.set(4);
                }
                if (low < entryPrice * (1 - p4) && pvflag.get(0) === 4) {
                    percent1 = 1;
                    //bState.set(0)
                    pvflag.set(5);
                    state.entryPrice = Number(0);
                    state.entryTime = Number(itime);
                    state.marketPosition = Number(0);
                }
                if (entryLow < entryPrice * (1 - p4) &&
                    pvflag.get(0) === 5 &&
                    close > entryLow + (input5_percentage / 100) * (entryPrice - entryLow)) {
                    target1 = 1;
                    bState.set(0);
                    pvflag.set(6);
                }
            }
            state.target1 = Number(p2);
            state.target2 = Number(p3);
            state.nowClose = Number(close);
            state.targetType = 'percent';
            state.symbolNumber = String(this.PineJS.Std.ticker(this._context));
            indicatorStoreSetStateDebounced(state);
            return [NaN, NaN, pv0, pv1, stoploss0, percent0, target0, stoploss1, percent1, target1];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 0,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    visible: !1,
                    color: '#ffffff',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 0,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    visible: !1,
                    color: '#ffffff',
                },
                plot_2: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 20,
                    visible: true,
                },
                plot_3: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 20,
                    visible: true,
                },
                plot_4: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 20,
                    visible: true,
                },
                plot_5: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 20,
                    visible: true,
                },
                plot_6: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 20,
                    visible: true,
                },
                plot_7: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 20,
                    visible: true,
                },
                plot_8: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 20,
                    visible: true,
                },
                plot_9: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 20,
                    visible: true,
                },
            },
            inputs: {
            //in_0: 33, in_1: 89, in_2: 200, in_3: 100, in_4: 80, in_5: 30, in_6: 0
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                title: '-',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '-',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '#Buy 1',
                title: '參考買訊',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '#SellShort 1',
                title: '參考賣訊',
            },
            plot_4: {
                isHidden: false,
                location: 'BelowBar',
                char: '×',
                size: 'small',
                title: '參考停損',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '*',
                size: 'small',
                title: '參考停利',
            },
            plot_6: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: '參考出場',
            },
            plot_7: {
                isHidden: false,
                location: 'AboveBar',
                char: '×',
                title: '參考停損',
            },
            plot_8: {
                isHidden: false,
                location: 'BelowBar',
                char: '*',
                size: 'small',
                title: '參考停利',
            },
            plot_9: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: '參考出場',
            },
        },
        is_price_study: !0,
        inputs: [
        // { id: 'in_0', name: '短周期均線', defval: 34, type: 'integer', min: 1, max: 10000 },
        // { id: 'in_1', name: '長周期均線', defval: 143, type: 'integer', min: 1, max: 10000 },
        // { id: 'in_2', name: '-', defval: 80, type: 'integer', min: 0, max: 10000 },
        // { id: 'in_3', name: '-', defval: 200, type: 'integer', min: 0, max: 10000 },
        // { id: 'in_4', name: '-', defval: 140, type: 'integer', min: 0, max: 10000 },
        // { id: 'in_5', name: '-', defval: 30, type: 'integer', min: 0, max: 100 },
        // {
        //   id: 'in_6',
        //   name: '部位選擇:多[1] 空[-1] 多空[0]',
        //   defval: 0,
        //   type: 'integer',
        //   min: -1,
        //   max: 1,
        // },
        ],
        scriptIdPart: '',
    },
});
