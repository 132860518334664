import React, { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import styled from '@emotion/styled';
import { flex } from '~/modules/AppLayout/FlexGridCss';
export const ButtonStyled = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
`;
export const QtyStateStore = proxy({
    state: 'long',
    state1: 'long',
    state2: 'long',
    state3: 'long',
});
export const QtyButton = memo(function QtyButton(props) {
    const state = useSnapshot(QtyStateStore);
    const setState = () => {
        if (props.group === 0) {
            return (QtyStateStore.state = props.useQty);
        }
        else if (props.group === 1) {
            return (QtyStateStore.state1 = props.useQty);
        }
        else if (props.group === 2) {
            return (QtyStateStore.state2 = props.useQty);
        }
        else if (props.group === 3) {
            return (QtyStateStore.state3 = props.useQty);
        }
    };
    const resultState = () => {
        if (props.group === 0) {
            return state.state;
        }
        else if (props.group === 1) {
            return state.state1;
        }
        else if (props.group === 2) {
            return state.state2;
        }
        else if (props.group === 3) {
            return state.state3;
        }
    };
    const selectedClasses = resultState() === props.useQty ? QtyButtonClasses.selected : QtyButtonClasses.default;
    return (<ButtonStyled onClick={() => setState()} className={selectedClasses}>
      {props.children}
    </ButtonStyled>);
});
export const QtyButtonClasses = {
    default: `QtyButton-default`,
    selected: `QtyButton-selected`,
};
// type QtyDefaultState = 'long' | 'short'
// type QtyMode = 'default' | 'realtime' | 'firebase'
// type QtyHoldingState = 'Holdinglong' | 'Holdingshort'
// type QtyRealtimeState = 'firebaseLong' | 'firebaseShort'
// type QtyFirebaseState = 'realtimeLong' | 'realtimeShort'
// export const QtyStateStore = proxy({
//   mode: 'default' as QtyMode,
//   defaultState: 'long' as QtyDefaultState,
//   realtimeState: 'firebaseLong' as QtyRealtimeState,
//   firebaseState: 'realtimeLong' as QtyFirebaseState,
// })
// export const QtyButton = memo<
//   ReactProps<{
//     mode: QtyMode
//     useQty: QtyDefaultState | QtyRealtimeState | QtyFirebaseState
//   }>
// >(function QtyButton(props) {
//   const state = useSnapshot(QtyStateStore)
//   const getState = () => {
//     if (state.mode === 'default') return state.defaultState
//     else if (state.mode === 'realtime') return state.realtimeState
//     else if (state.mode === 'firebase') return state.firebaseState
//   }
//   const selectedClasses =
//     getState() === props.useQty ? QtyButtonClasses.selected : QtyButtonClasses.default
//   const setState = () => {
//     if (state.mode === 'default')
//       return (QtyStateStore.defaultState = props.useQty as QtyDefaultState)
//     else if (state.mode === 'realtime')
//       return (QtyStateStore.realtimeState = props.useQty as QtyRealtimeState)
//     else if (state.mode === 'firebase')
//       return (QtyStateStore.firebaseState = props.useQty as QtyFirebaseState)
//   }
//   return (
//     <ButtonStyled
//       onClick={() => setState()}
//       className={selectedClasses}
//     >
//       {props.children}
//     </ButtonStyled>
//   )
// })
