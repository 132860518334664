import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indicatorStoreSetStateDebounced, useIndicatorStore } from '~/store/useIndicatorStore';
export const goldbrain_cdp = createIndicator({
    id: 'goldbraincdp',
    displayName: 'CDP',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const state = useIndicatorStore.getState();
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const daily_high = this._context.new_var();
            const low = this.PineJS.Std.low(this._context);
            const daily_low = this._context.new_var();
            const daily_close = this._context.new_var();
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            const itime = this.PineJS.Std.time(this._context);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const date = new Date(itime).getDate();
            const date_array = this._context.new_var();
            const cdp = this._context.new_var();
            const ah = this._context.new_var();
            const nh = this._context.new_var();
            const nl = this._context.new_var();
            const al = this._context.new_var();
            date_array.get(1);
            if (resolution !== '1D') {
                date_array.set(date);
                if (high > daily_high) {
                    daily_high.set(high);
                }
                if (low < daily_low) {
                    daily_low.set(low);
                }
                if (date > date_array.get(1)) {
                    daily_close.set(close);
                    cdp.set((daily_high + daily_low + close + close) / 4);
                    ah.set(cdp + (daily_high - daily_low));
                    nh.set(cdp * 2 - daily_low);
                    nl.set(cdp * 2 - daily_high);
                    al.set(cdp - (daily_high - daily_low));
                }
                if (date > date_array.get(1)) {
                    daily_high.set(high);
                    daily_low.set(low);
                    date_array.set(date);
                }
            }
            if (resolution === '1D') {
                cdp.set((high + low + close + close) / 4);
                ah.set(cdp + (high - low));
                nh.set(cdp * 2 - low);
                nl.set(cdp * 2 - high);
                al.set(cdp - (high - low));
            }
            state.valueArray1 = [ah.get(0), nh.get(0), cdp.get(0), nl.get(0), al.get(0)];
            indicatorStoreSetStateDebounced(state);
            return [
                this.PineJS.Std.sma(cdp, 1, this._context),
                this.PineJS.Std.sma(ah, 1, this._context),
                this.PineJS.Std.sma(nh, 1, this._context),
                this.PineJS.Std.sma(nl, 1, this._context),
                this.PineJS.Std.sma(al, 1, this._context),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#999999',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#aa0000',
                },
                plot_2: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#aa0000',
                },
                plot_3: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00aa00',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00aa00',
                },
            },
            precision: 2,
            inputs: {},
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_3: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_4: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !0,
        inputs: [],
    },
});
