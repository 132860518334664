import styled from '@emotion/styled';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import React, { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
export const ButtonStyled = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
`;
export const IndicatorsStore = proxy({
    indicatorsMode: 1,
});
export const IndicatorsButton = memo(function IndicatorsButton(props) {
    const state = useSnapshot(IndicatorsStore);
    const isSelected = state.indicatorsMode === props.statusNumber;
    const selectedClasses = state.indicatorsMode === props.statusNumber
        ? IndicatorsButtonClasses.selected
        : IndicatorsButtonClasses.default;
    return (<>
      {!isSelected ? (<props.useChart.IndicatorsSwitch indicators={props.indicators}>
          <ButtonStyled onClick={() => {
                IndicatorsStore.indicatorsMode = props.statusNumber;
                props.useChart
                    .getState()
                    .widget?.activeChart()
                    .setResolution(props.interval, function callback() {
                    // noop
                });
                props.paneHight !== undefined &&
                    props.useChart.getState().changePaneRatio(props.paneHight);
            }} className={selectedClasses}>
            {props.children}
          </ButtonStyled>
        </props.useChart.IndicatorsSwitch>) : (<ButtonStyled className={selectedClasses}>{props.children}</ButtonStyled>)}
    </>);
});
export const IndicatorsButtonClasses = {
    default: `IndicatorsButton-default`,
    selected: `IndicatorsButton-selected`,
};
